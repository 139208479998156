import { RichTextRenderer } from '@contember/react-client'
import type { OurWorkPageResult } from '../data/content/OurWorkPageFragment'
import { ContentGroups } from './ContentGroups'

export type OurWorkProps = {
	ourWorkPage: OurWorkPageResult
}

export function OurWorkPage(props: OurWorkProps) {
	return (
		<div className="works">
			<h1 className="typo-heading">{props.ourWorkPage.title}</h1>

			<div className="typo-perex">
				{props.ourWorkPage.leadParagraph && (
					<RichTextRenderer source={props.ourWorkPage.leadParagraph} />
				)}
			</div>
			<ContentGroups contentGroups={props.ourWorkPage.contentGroups} />
		</div>
	)
}
