import clsx from 'clsx'
import React from 'react'
import type { FooterResult } from '../data/content/FooterFragment'
import type { HomePageResult } from '../data/content/HomePageFragment'
import { Link } from './Link'

export type WorkWithProps = {
	workWith: FooterResult
	homePage?: HomePageResult
	isContactPage: boolean
}

export function WorkWith(props: WorkWithProps) {
	return (
		<div className={clsx('seeWorks', props.isContactPage && 'view-hidden')}>
			<h2 className="typo-heading">
				{props.homePage?.directorsText
					? props.homePage?.directorsText
					: props.workWith.directorsText}
			</h2>

			{props.workWith.directorsButton && (
				<Link className="btn" link={props.workWith.directorsButton} />
			)}
		</div>
	)
}
