import { RichTextRenderer } from '@contember/react-client'
import React from 'react'
import type { DirectorResult } from '../data/content/DirectorFragment'
import { ContemberImage } from './ContemberImage'
import { ContentGroups } from './ContentGroups'

export type DirectorProps = {
	director: DirectorResult
}

export function Director(props: DirectorProps) {
	return (
		<div className="profile view-large">
			<span className="profile-photo">
				{props.director.image && (
					<ContemberImage image={props.director.image} layout="fill" objectFit="cover" />
				)}
			</span>
			<h2 className="profile-name">{props.director.name}</h2>
			<span className="typo-perex">
				{props.director.leadParagraph && <RichTextRenderer source={props.director.leadParagraph} />}
			</span>
			<ContentGroups contentGroups={props.director.contentGroups} />
		</div>
	)
}
