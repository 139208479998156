import type { ContactResult } from '../data/content/ContactFragment'
import { ContemberImage } from './ContemberImage'

export type ContactProps = {
	contact: ContactResult
}

export function Contact(props: ContactProps) {
	return (
		<>
			<span className="profile-photo">
				{props.contact.image && (
					<ContemberImage image={props.contact.image} layout="fill" objectFit="cover" />
				)}
			</span>
			<p className="profile-name">{props.contact.name}</p>
			<p className="profile-infoline position view-small">{props.contact.position}</p>
			<a href={`tel:${props.contact.phone}`} className="profile-infoline phone view-nowrap">
				Mob {props.contact.phone}
			</a>
			<a href={`mailto:${props.contact.email}`} className="profile-infoline">
				{props.contact.email}
			</a>
		</>
	)
}
