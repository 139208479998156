import React from 'react'
import Modal from 'react-modal'
import type { FilmItemResult } from '../data/content/FilmItemFragment'
import { Icon } from './Icon'

Modal.setAppElement('html')

export type VideoModalProps = {
	isOpen: boolean
	film: FilmItemResult['film']
	onRequestClose: React.ComponentProps<typeof Modal>['onRequestClose']
}

export function VideoModal(props: VideoModalProps) {
	return (
		<Modal
			isOpen={props.isOpen}
			className="videoModal-box"
			onRequestClose={props.onRequestClose}
			overlayClassName="videoModal">
			<div>
				<iframe
					className="videoModal-iFrame"
					key={props.film?.id}
					src={`https://player.vimeo.com/video/${props.film?.vimeoId}`}
					title={props.film?.name}
					width="1920"
					height="1080"
					allow="autoplay; fullscreen; picture-in-picture"
					allowFullScreen></iframe>
				<button className="videoModal-close" onClick={props.onRequestClose}>
					<Icon name="cross" />
				</button>
			</div>
		</Modal>
	)
}
