import { RichTextRenderer } from '@contember/react-client'
import React from 'react'
import type { PhotographerResult } from '../data/content/PhotographerFragment'
import { ContemberImage } from './ContemberImage'
import { ContentGroups } from './ContentGroups'

export type PhotographerProps = {
	photographer: PhotographerResult
}

export function Photographer(props: PhotographerProps) {
	return (
		<div className="profile view-large">
			<span className="profile-photo">
				{props.photographer.image && (
					<ContemberImage image={props.photographer.image} layout="fill" objectFit="cover" />
				)}
			</span>
			<h2 className="profile-name">{props.photographer.name}</h2>
			<span className="typo-perex">
				{props.photographer.leadParagraph && (
					<RichTextRenderer source={props.photographer.leadParagraph} />
				)}
			</span>
			<ContentGroups contentGroups={props.photographer.contentGroups} />
		</div>
	)
}
