import clsx from 'clsx'
import React, { useState } from 'react'
import type { ContentGroupResult } from '../data/content/ContentGroupFragment'
import { ContemberImage } from './ContemberImage'
import { Film } from './Film'

export type ContentGroupsProps = {
	contentGroups: Array<{ contentGroup?: ContentGroupResult }>
}

export function ContentGroups(props: ContentGroupsProps) {
	const [activeLocation, setActiveLocation] = useState(props.contentGroups[0]?.contentGroup?.id)

	return (
		<div className="tabs">
			{props.contentGroups.length > 1 && (
				<div className="tabs-bar">
					{props.contentGroups.map(
						({ contentGroup }) =>
							contentGroup && (
								<button
									key={contentGroup.id}
									className={clsx('tabs-item', contentGroup.id === activeLocation && 'is-active')}
									onClick={() => setActiveLocation(contentGroup?.id)}>
									{contentGroup.title}
								</button>
							)
					)}
				</div>
			)}
			{props.contentGroups.map(
				({ contentGroup }) =>
					contentGroup && (
						<div
							key={contentGroup.id}
							className={clsx('tabs-content', contentGroup.id === activeLocation && 'is-active')}>
							<div className="videoPreview-container">
								{contentGroup.films.map((item) => (
									<React.Fragment key={item.id}>
										<Film key={item.id} film={item.film} />
									</React.Fragment>
								))}
							</div>
							<div className="videoPreview-container view-singlecolumn">
								{contentGroup.gallery?.items.map((item) => (
									<span key={item.image?.id} className="videoPreview-item">
										{item.image && (
											<ContemberImage image={item.image} objectFit="cover" layout="responsive" />
										)}
									</span>
								))}
							</div>
						</div>
					)
			)}
		</div>
	)
}
