import type { HomePageResult } from '../data/content/HomePageFragment'
import { ContemberImage } from './ContemberImage'
import { ContentGroups } from './ContentGroups'

export type HomeProps = {
	homePage: HomePageResult
}

export function HomePage(props: HomeProps) {
	return (
		<>
			<div className="front">
				<div className="front-content">
					<span className="shape shape-logo view-big view-white front-logo">
						{props.homePage.logo && <ContemberImage image={props.homePage.logo} />}
					</span>
					<p className="front-tagline">{props.homePage.tagline}</p>
				</div>
				<div className="front-background">
					{props.homePage.backgroundImage && (
						<ContemberImage
							image={props.homePage.backgroundImage}
							layout="fill"
							objectFit="cover"
						/>
					)}
				</div>
			</div>
			<ContentGroups contentGroups={props.homePage.contentGroups} />
		</>
	)
}
