import { RichTextRenderer } from '@contember/react-client'
import type { DirectorLocationResult } from '../data/content/DirectorsLocationFragment'
import type { DirectorsPageResult } from '../data/content/DirectorsPageFragment'
import { ContemberImage } from './ContemberImage'
import { Tabs } from './Tabs'

export type DirectorsProps = {
	directorsPage: DirectorsPageResult
	listDirectorLocation: DirectorLocationResult[]
}

export function DirectorsPage(props: DirectorsProps) {
	return (
		<div className="workWith view-singlePage">
			<p className="typo-heading">{props.directorsPage.title}</p>
			<span className="typo-perex">
				{props.directorsPage.leadParagraph && (
					<RichTextRenderer source={props.directorsPage.leadParagraph} />
				)}
			</span>
			<Tabs
				items={props.listDirectorLocation.map((item) => {
					return {
						title: item.city || '',
						content: (
							<div className="contactTabs-content">
								<div className="profileGallery directors">
									{item.director.map((director) => (
										<a href={director.link?.url} key={director.id} className="profile">
											<span className="profile-photo profile-photo">
												{director.image && (
													<ContemberImage image={director.image} layout="fill" objectFit="cover" />
												)}
											</span>
											<p className="profile-name">{director.name}</p>
										</a>
									))}
								</div>
							</div>
						),
					}
				})}
			/>
		</div>
	)
}
