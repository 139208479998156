import clsx from 'clsx'
import React from 'react'
import type { FilmItemResult } from '../data/content/FilmItemFragment'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import { VideoModal } from './VideoModal'

export type FilmProps = {
	film: FilmItemResult['film']
}

export function Film(props: FilmProps) {
	const [modalIsOpen, setIsOpen] = React.useState(false)

	function openModal() {
		setIsOpen(true)
	}

	function closeModal() {
		setIsOpen(false)
	}

	return (
		<>
			<button onClick={openModal} className={clsx('videoPreview-item')}>
				<div className="videoPreview-spacer"></div>
				<span className="videoPreview-image">
					{props.film?.posterImage && (
						<ContemberImage image={props.film?.posterImage} objectFit="cover" layout="fill" />
					)}
				</span>
				<div className="videoPreview-cover">
					<div></div>
					<span className="shape shape-play videoPreview-play">
						<Icon name="play" />
					</span>
					<div className="videoPreview-info">
						<p className="videoPreview-title">{props.film?.name}</p>
						<p className="videoPreview-subtitle">{props.film?.author}</p>
					</div>
				</div>
			</button>

			<VideoModal film={props.film} isOpen={modalIsOpen} onRequestClose={closeModal} />
		</>
	)
}
