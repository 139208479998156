import clsx from 'clsx'
import { useState } from 'react'

export type TabItem = {
	title: string
	subtitle?: string
	content: React.ReactChild
}

export type TabsProps = {
	items: TabItem[]
}

export function Tabs(props: TabsProps) {
	const [activeIndex, setActiveIndex] = useState(0)

	return (
		<div className="contactTabs">
			<div className="contactTabs-tabs">
				{props.items?.map((item, index) => (
					<button
						key={index}
						className={clsx('contactTabs-tab', index === activeIndex && 'is-active')}
						onClick={() => setActiveIndex(index)}>
						<h2 className="contactTabs-city">{item.title}</h2>
						{item.subtitle && <h3 className="contactTabs-country">{item.subtitle}</h3>}
					</button>
				))}
			</div>
			{props.items.map((item, index) => (
				<div
					key={index}
					className={clsx('contactTabs-contents', index === activeIndex && 'is-active')}>
					{item.content}
				</div>
			))}
		</div>
	)
}
