import { RichTextRenderer } from '@contember/react-client'
import type { TabletopPageResult } from '../data/content/TabletopPageFragment'
import { ContemberImage } from './ContemberImage'
import { ContentGroups } from './ContentGroups'

export type TabletopProps = {
	tabletopPage: TabletopPageResult
}

export function TabletopPage(props: TabletopProps) {
	return (
		<>
			<div className="workWith view-singlePage tabletop">
				<h1 className="typo-heading">{props.tabletopPage.title}</h1>

				<div className="typo-perex tabletop-perex">
					{props.tabletopPage.leadParagraph && (
						<RichTextRenderer source={props.tabletopPage.leadParagraph} />
					)}
				</div>
			</div>
			<div className="profile view-large tabletop-profile">
				<span className="profile-photo">
					{props.tabletopPage.logo && <ContemberImage image={props.tabletopPage.logo} />}
				</span>
				<h2 className="profile-name">{props.tabletopPage.teamTitle}</h2>
			</div>
			<ContentGroups contentGroups={props.tabletopPage.contentGroups} />
		</>
	)
}
