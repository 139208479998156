import clsx from 'clsx'
import { useRouter } from 'next/dist/client/router'
import React, { useEffect, useState } from 'react'
import type { HeaderResult } from '../data/content/HeaderFragment'
import { ContemberImage } from './ContemberImage'
import { Link } from './Link'

export type HeaderProps = {
	header: HeaderResult
	isHomepage: boolean
}

export function Header(props: HeaderProps) {
	const [isChecked, setIsChecked] = useState(false)
	const router = useRouter()

	useEffect(() => {
		setIsChecked(false)
	}, [router.asPath])

	return (
		<>
			<div className={clsx('nav', props.isHomepage && 'view-overlay')}>
				<a className="nav-logo" href="/">
					<span className="shape shape-logo">
						{props.header.logo && <ContemberImage image={props.header.logo} />}
					</span>
				</a>
				<input
					className="nav-checkbox"
					type="checkbox"
					id="menu-checkbox"
					checked={isChecked}
					onChange={(event) => setIsChecked(event.target.checked)}
				/>
				<label htmlFor="menu-checkbox" className="nav-trigger">
					Menu
				</label>
				<div className="nav-menu">
					<div className="nav-center">
						{props.header.links.map((item, i) => (
							<React.Fragment key={i}>
								{item.link && (
									<Link className="nav-item" link={item.link} activeClassName="is-active" />
								)}
							</React.Fragment>
						))}
					</div>
					<div className="nav-right">
						<a className="nav-item" href="http://filmservice.idc.cz/users/login">
							Client Area
						</a>
					</div>
				</div>
			</div>
		</>
	)
}
