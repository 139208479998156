import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import React from 'react'
import { ContactsPage } from '../app/components/ContactsPage'
import { Director } from '../app/components/Director'
import { DirectorsPage } from '../app/components/DirectorsPage'
import { Footer } from '../app/components/Footer'
import { Header } from '../app/components/Header'
import { HomePage } from '../app/components/HomePage'
import { OurWorkPage } from '../app/components/OurWorkPage'
import { Photographer } from '../app/components/Photographer'
import { PhotographersPage } from '../app/components/PhotographersPage'
import { TabletopPage } from '../app/components/TabletopPage'
import { ActiveUrlContext } from '../app/contexts/ActiveUrlContext'
import { combineUrl } from '../app/data/combineUrl'
import { ContactLocationFragment } from '../app/data/content/ContactLocationFragment'
import { ContactsPageFragment } from '../app/data/content/ContactsPageFragment'
import { DirectorFragment } from '../app/data/content/DirectorFragment'
import { DirectorLocationFragment } from '../app/data/content/DirectorsLocationFragment'
import { DirectorsPageFragment } from '../app/data/content/DirectorsPageFragment'
import { FooterFragment } from '../app/data/content/FooterFragment'
import { HeaderFragment } from '../app/data/content/HeaderFragment'
import { HomePageFragment } from '../app/data/content/HomePageFragment'
import { OurWorkPageFragment } from '../app/data/content/OurWorkPageFragment'
import { PhotographerFragment } from '../app/data/content/PhotographerFragment'
import { PhotographerLocationFragment } from '../app/data/content/PhotographersLocationFragment'
import { PhotographersPageFragment } from '../app/data/content/PhotographersPageFragment'
import { TabletopPageFragment } from '../app/data/content/TabletopPageFragment'
import { One, OrderDirection, Thunder } from '../generated/content'
import { RespondWithNotFound, serverPropsHandler } from '../libs/next/api/handlers'
import { getZeusConnection } from '../libs/next/loaders/graphql'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage(props: CommonPageProps) {
	const activeUrl = props.data.getLinkable?.url ?? null
	const header = props.data.getHeader
	const {
		contactsPage,
		director,
		directorsPage,
		homePage,
		ourWorkPage,
		photographer,
		photographersPage,
		tabletopPage,
	} = props.data.getLinkable ?? {}
	const footer = props.data.getFooter

	return (
		<>
			<Head>
				<title>Filmservice</title>
				<link rel="icon" href="/favicon.ico" />
			</Head>
			<ActiveUrlContext.Provider value={activeUrl}>
				{header && <Header header={header} isHomepage={Boolean(homePage)} />}
				{contactsPage && (
					<ContactsPage
						contactsPage={contactsPage}
						listContactLocation={props.data.listContactLocation}
					/>
				)}
				{director && <Director director={director} />}
				{directorsPage && (
					<DirectorsPage
						directorsPage={directorsPage}
						listDirectorLocation={props.data.listDirectorLocation}
					/>
				)}
				{photographer && <Photographer photographer={photographer} />}
				{photographersPage && (
					<PhotographersPage
						photographersPage={photographersPage}
						listPhotographerLocation={props.data.listPhotographerLocation}
					/>
				)}
				{homePage && <HomePage homePage={homePage} />}
				{ourWorkPage && <OurWorkPage ourWorkPage={ourWorkPage} />}
				{tabletopPage && <TabletopPage tabletopPage={tabletopPage} />}
				{footer && (
					<Footer footer={footer} homePage={homePage} isContactPage={Boolean(contactsPage)} />
				)}
			</ActiveUrlContext.Provider>

			{/* <section>
				<h1>
					Props from <code>getServerSideProps</code>
				</h1>
				<Dump data={props} />
			</section>

			<section>
				<h1>
					Server-side number sum with <code>/api/v1/[handler]</code>
				</h1>
				<SumForm />
			</section> */}
		</>
	)
}

export const getServerSideProps = serverPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const urlInfo = combineUrl(context)

	const url = `${urlInfo.path}`

	const data = await content.query({
		// ...SiteFragment(urlInfo.locale),
		getHeader: [
			{
				by: {
					unique: One.One,
				},
			},
			HeaderFragment(),
		],
		listContactLocation: [{ orderBy: [{ order: OrderDirection.asc }] }, ContactLocationFragment()],
		listDirector: [{}, DirectorFragment()],
		listDirectorLocation: [{}, DirectorLocationFragment()],
		listPhotographerLocation: [{}, PhotographerLocationFragment()],
		getLinkable: [
			{ by: { url } },
			{
				id: true,
				url: true,
				contactsPage: [{}, ContactsPageFragment()],
				director: [{}, DirectorFragment()],
				directorsPage: [{}, DirectorsPageFragment()],
				homePage: [{}, HomePageFragment()],
				ourWorkPage: [{}, OurWorkPageFragment()],
				photographer: [{}, PhotographerFragment()],
				photographersPage: [{}, PhotographersPageFragment()],
				tabletopPage: [{}, TabletopPageFragment()],
			},
		],
		getFooter: [
			{
				by: {
					unique: One.One,
				},
			},
			FooterFragment(),
		],
	})

	if (
		!data.getLinkable?.contactsPage &&
		!data.getLinkable?.director &&
		!data.getLinkable?.directorsPage &&
		!data.getLinkable?.homePage &&
		!data.getLinkable?.ourWorkPage &&
		!data.getLinkable?.photographer &&
		!data.getLinkable?.photographersPage &&
		!data.getLinkable?.tabletopPage
	) {
		throw new RespondWithNotFound('Blog post not found')
	}

	return {
		props: {
			url,
			urlInfo,
			data,
			serverTime: new Date().toString(),
		},
	}
})
