import React from 'react'
import type { FooterResult } from '../data/content/FooterFragment'
import type { HomePageResult } from '../data/content/HomePageFragment'
import { ContactPreview } from './ContactPreview'
import { ContemberImage } from './ContemberImage'
import { FooterAppIcon } from './FooterAppIcon'
import { WorkWith } from './WorkWith'

export type FooterProps = {
	footer: FooterResult
	homePage?: HomePageResult
	isContactPage: boolean
}

export function Footer(props: FooterProps) {
	return (
		<>
			<div>
				<WorkWith
					workWith={props.footer}
					homePage={props.homePage}
					isContactPage={props.isContactPage}
				/>
				<ContactPreview contactPreview={props.footer} isContactPage={props.isContactPage} />
			</div>
			<div className="footer">
				<div className="footer-inner">
					<div className="footer-left">
						{props.footer.logo && <ContemberImage image={props.footer.logo} />}
						<div className="footer-socials">
							{props.footer.socialLinks?.map((item, i) => (
								<React.Fragment key={i}>
									{item.link && (
										<div className="footer-social">
											{item.type && <FooterAppIcon url={item.link} type={item.type} />}
											<a className="footer-social footer-socialText" href={item.link}>
												{item.linkLabel}
											</a>
										</div>
									)}
								</React.Fragment>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
