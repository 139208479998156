import { SocialLinkType } from '../../generated/content'
import { Icon } from './Icon'

const appIcons = {
	[SocialLinkType.facebook]: (
		<span className="Icons">
			<Icon name="facebook" />
		</span>
	),
	[SocialLinkType.instagram]: (
		<span className="Icons">
			<Icon name="instagram" />
		</span>
	),
	[SocialLinkType.vimeo]: (
		<span className="Icons">
			<Icon name="vimeo" />
		</span>
	),
}
export function FooterAppIcon(props: { url: string; type: SocialLinkType }) {
	return <a href={props.url}>{appIcons[props.type]}</a>
}
