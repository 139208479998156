import clsx from 'clsx'
import React from 'react'
import type { FooterResult } from '../data/content/FooterFragment'
import { Link } from './Link'

export type ContactPreviewProps = {
	contactPreview: FooterResult
	isContactPage: boolean
}

export function ContactPreview(props: ContactPreviewProps) {
	return (
		<div className={clsx('contactPreview', props.isContactPage && 'view-hidden')}>
			<h2 className="contactPreview-title">{props.contactPreview.contactText}</h2>
			{props.contactPreview.contactButton && (
				<Link className="btn view-white" link={props.contactPreview.contactButton} />
			)}
		</div>
	)
}
