import { RichTextRenderer } from '@contember/react-client'
import type { ContactLocationResult } from '../data/content/ContactLocationFragment'
import type { ContactsPageResult } from '../data/content/ContactsPageFragment'
import { Contact } from './Contact'
import { ContactLocation } from './ContactLocation'
import { Tabs } from './Tabs'

export type ContactsPageProps = {
	contactsPage: ContactsPageResult
	listContactLocation: ContactLocationResult[]
}

export function ContactsPage(props: ContactsPageProps) {
	return (
		<div className="contact">
			<h1 className="typo-heading">{props.contactsPage.title}</h1>

			{props.contactsPage.leadParagraph && (
				<div className="typo-perex">
					<RichTextRenderer source={props.contactsPage.leadParagraph} />
				</div>
			)}
			<Tabs
				items={props.listContactLocation.map((item) => {
					return {
						title: item.city || '',
						subtitle: item.state,
						content: (
							<>
								<div className="contactTabs-content">
									<div className="contactTabs-column contactTabs-place">
										<ContactLocation contactLocation={item} />
									</div>
									<div className="contactTabs-column">
										<div className="profileGallery view-contact">
											{item.contact.map((contact) => (
												<div key={contact.id} className="profile view-medium">
													<Contact contact={contact} />
												</div>
											))}
										</div>
									</div>
								</div>
							</>
						),
					}
				})}
			/>
		</div>
	)
}
