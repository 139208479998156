import { RichTextRenderer } from '@contember/react-client'
import type { ContactLocationResult } from '../data/content/ContactLocationFragment'

export type ContactLocationProps = {
	contactLocation: ContactLocationResult
}

export function ContactLocation(props: ContactLocationProps) {
	return (
		<div className="place">
			<span className="place-info">
				{props.contactLocation.address && (
					<RichTextRenderer source={props.contactLocation.address} />
				)}
			</span>
			<div>
				<a href={`mailto:${props.contactLocation.email}`} className="place-info">
					{props.contactLocation.email}
				</a>
			</div>
			<div className="place-contacts">
				<div>
					{props.contactLocation.phone && <strong className="place-contactLabel">TEL</strong>}
					<a href={`tel:${props.contactLocation.phone}`} className="place-info">
						{props.contactLocation.phone}
					</a>
				</div>
				<div>
					{props.contactLocation.fax && <strong className="place-contactLabel">FAX</strong>}
					<span className="place-info">{props.contactLocation.fax}</span>
				</div>
			</div>
			<div className="place-legal">
				{props.contactLocation.note && <RichTextRenderer source={props.contactLocation.note} />}
			</div>
		</div>
	)
}
