import { RichTextRenderer } from '@contember/react-client'
import type { PhotographerLocationResult } from '../data/content/PhotographersLocationFragment'
import type { PhotographersPageResult } from '../data/content/PhotographersPageFragment'
import { ContemberImage } from './ContemberImage'
import { ContentGroups } from './ContentGroups'
import { Tabs } from './Tabs'

export type PhotographersProps = {
	photographersPage: PhotographersPageResult
	listPhotographerLocation: PhotographerLocationResult[]
}

export function PhotographersPage(props: PhotographersProps) {
	return (
		<div className="workWith view-singlePage">
			<p className="typo-heading">{props.photographersPage.title}</p>
			<span className="typo-perex">
				{props.photographersPage.leadParagraph && (
					<RichTextRenderer source={props.photographersPage.leadParagraph} />
				)}
			</span>

			<Tabs
				items={props.listPhotographerLocation.map((item) => {
					return {
						title: item.city || '',
						content: (
							<div className="contactTabs-content">
								<div className="profileGallery directors">
									{item.photographer.map((photographer) => (
										<a href={photographer.link?.url} key={photographer.id} className="profile">
											<span className="profile-photo profile-photo">
												{photographer.image && (
													<ContemberImage
														image={photographer.image}
														layout="fill"
														objectFit="cover"
													/>
												)}
											</span>
											<p className="profile-name">{photographer.name}</p>
										</a>
									))}
								</div>
							</div>
						),
					}
				})}
			/>
			<ContentGroups contentGroups={props.photographersPage.contentGroups} />
		</div>
	)
}
